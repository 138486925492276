import React from "react";

import Mascot from "../../ESC images/eg-overview-man.png";
import overviewImg from "../../ESC images/overview-image.png";

const About = () => {
  return (
    <div>
      <div className="overview">
        <div className="overview-card">
          <div className="moving-image-container">
            <img src={Mascot} alt="Mascot" className="moving-image" />
          </div>
          <div className="overview-image">
            <img src={overviewImg} alt="Overview Image" />
          </div>
          <div className="overview-content">
            <h2>Welcome to Easy Smart Cleaners</h2>
            <p>
              The best cleaning service provider from South India - Bangalore.
              We take great pride in offering all of our clients a reliable,
              trustworthy, and affordable service. We've steadily grown and
              built a reputation for excellence. We guarantee that you will
              receive the highest standard of service for the best possible
              price. Easy Smart Cleaners offers impeccable service from start to
              finish. We offer a broad range of cleaning services for
              Residential, corporate, Industrial, and others, throughout
              Bangalore.
            </p>
            <p>
              We take the time to understand each of our client’s needs, in
              order to ensure that they receive the best possible bespoke
              cleaning services for their premises. Our cleaning staff are well
              trained, motivated, and supported by a team of local,
              knowledgeable, and experienced operational managers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
