import React from "react";

const WelcomePage = ({ welcomePageInfo }) => {
  return (
    <div>
      <div className="service-intro">
        <img src={welcomePageInfo[0].src} alt={welcomePageInfo[0].alt} />
      </div>
      <div className="service-intro">
        <h2>{welcomePageInfo[0].title}</h2>
        <p>{welcomePageInfo[0].desc}</p>
      </div>
    </div>
  );
};

export default WelcomePage;
