import React from "react";

const Contact = () => {
  return (
    <div className="contact-section">
      <div className="contact-info">
        {/* Your other contact information here */}
        <h1>CONTACT US</h1>
        <div className="info">
          <div className="info-sub">
            <i className="fas fa-phone-alt"></i>
            <p>Call Us: +91 96631 50947, +91 88615 11878</p>
          </div>
          <div className="info-sub">
            <i className="fas fa-map-marker-alt"></i>
            <p>
              Location: No-4, Medarahalli, Medarahalli Post, Bangalore-560090
            </p>
          </div>
          <div className="info-sub">
            <i className="fas fa-clock"></i>
            <p>Business Hours: Mon - Sun, From 9:30 am to 8:00 pm</p>
          </div>
          <a href="https://facebook.com" target="_blank">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com" target="_blank">
            <i className="fa-brands fa-x-twitter"></i>
          </a>
          <a href="https://instagram.com" target="_blank">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://wa.me/9663150947" target="_blank">
            <i className="fab fa-whatsapp"></i>
          </a>
          <a href="mailto:support@easysmartcleaners.com">
            <i className="fas fa-envelope"></i>
          </a>
        </div>
      </div>
      <div className="contact-form-container">
        <form id="contact-form" action="YOUR_ENDPOINT" method="POST">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" required />

          <label htmlFor="phone">Phone Number:</label>
          <input type="tel" id="phone" name="phone" required />

          <label htmlFor="services">Services:</label>
          <select id="services" name="services">
            <option value="home-deep-cleaning">Home Deep Cleaning</option>
            <option value="sofa-shampooing">Sofa Shampooing</option>
            <option value="bathroom-cleaning">Bathroom Cleaning</option>
            <option value="pest-control">Pest Control</option>
            <option value="carpet-shampooing">Carpet Shampooing</option>
            <option value="dining-table-chairs">Dining Table Chairs</option>
            <option value="chimney-cleaning">Chimney Cleaning</option>
            <option value="window-cleaning">Window Cleaning</option>
            <option value="fridge-deep-cleaning">Fridge Deep Cleaning</option>
            <option value="mattress-cleaning">Mattress Cleaning</option>
            <option value="kitchen-cleaning">Kitchen Cleaning</option>
            <option value="combo-offer">Combo Offer</option>
            <option value="marble-floor-polishing">
              Marble / Floor Polishing
            </option>
            <option value="office-cleaning">Office Cleaning</option>
            <option value="home-painting">Home Painting</option>
            <option value="disinfection-services">Disinfection Services</option>
            <option value="curtain-steam-cleaning">
              Curtain Steam Cleaning
            </option>
            <option value="exterior-cleaning">Exterior Cleaning</option>
          </select>
          <fieldset>
            <legend>Address:</legend>
            <input
              type="text"
              id="door-number"
              name="door_number"
              placeholder="Door Number"
              required
            />
            <input
              type="text"
              id="street-name"
              name="street_name"
              placeholder="Street Name"
              required
            />
            <input
              type="text"
              id="area-name"
              name="area_name"
              placeholder="Area Name"
              required
            />
            <input
              type="text"
              id="city"
              name="city"
              placeholder="City"
              required
            />
            <input
              type="text"
              id="state"
              name="state"
              placeholder="state"
              required
            />
            <input
              type="text"
              id="pincode"
              name="pincode"
              placeholder="pincode"
              required
            />
            {/* Add more address fields here  */}
          </fieldset>

          <label htmlFor="more-info">More Information:</label>
          <textarea id="more-info" name="more_information"></textarea>

          <label className="custom-checkbox">
            Receive WhatsApp Notifications:
            <input
              type="checkbox"
              id="whatsapp-notif"
              name="whatsapp_notification"
            />
            <span className="checkmark"></span>
          </label>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
