import React, { useEffect, useRef }  from "react";

const About = ({ serviceData }) => {

  const divRef = useRef();

  const scrollToElement = () => {
    const { current } = divRef;
    if (current !== null) {
      current.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    // Check if all necessary components are loaded
    const areComponentsLoaded = serviceData !== undefined;
    if (areComponentsLoaded) {
      scrollToElement();
    }
  }, [serviceData]);



  return (
    <>
      <div className="service-intro" ref={divRef}>
      {/* <div className="service-intro" > */}
        <h2 style={{ fontSize: "3rem" }}>{serviceData?.ServiceName.S}</h2>
        <p>{serviceData?.ServiceDescription?.S}</p>
      </div>
    </>
  );
};

export default About;
