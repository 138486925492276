import React, { useEffect, useRef, useState } from "react";
import Plans from "./Plans";
import Details from "./Details";
import CleaningServices from "../Reusable Components/CleaningServices";

const Options = ({serviceData,cart, totalPrice,  updateCartView,calculateTotalPrice, setCart,setTotalPrice,addToCart}) => {

  const [productData, setProductData] = useState([]);
  const [packageData,setPackageData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [particularPackage,setParticularPackage] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  
  useEffect(() => {

    const fetchData = async () => {
      try {
        // Fetch data from a URL
        const productResponse = await fetch(`https://mozrgptz3a.execute-api.ap-south-1.amazonaws.com/ESC-Production/esc-product?timestamp=${Date.now()}`);
        const packageResponse = await fetch(`https://mozrgptz3a.execute-api.ap-south-1.amazonaws.com/ESC-Production/esc-package?timestamp=${Date.now()}`);

        if (!productResponse.ok  &&  packageResponse) {
          throw new Error('Network response was not ok');
        }

        const fetchedProductData = await productResponse.json();
        const fetchedPackageData = await packageResponse.json();
        
        setProductData(fetchedProductData); // Set the fetched data
        setPackageData(fetchedPackageData)
        setLoading(false); // Set loading to false after data is fetched
        
      } catch (error) {
        console.error('Error fetching data:', error);
      } 
    };
    fetchData();
  }, []);
  
  
  const productInfo = [];
  const packageInfo = [];

  productData.map( (el,idx) =>{
    if(el.ServiceID.S === serviceData?.ServiceID.S)
    {
      const newProductInfo = [
        {
          ProductName: el.ProductName,
          ProductImage: el.ProductImage,
        }
      ]
     productInfo.push(newProductInfo)
    }
  } )


  productData.map( (product,idx) =>{
    packageData.map( (packages,idx) => {
      if(product.ServiceID.S === serviceData?.ServiceID.S)
        if(product.ProductName.S === packages.ProductName.S)
          packageInfo.push(packages)
    })
  } )

  // const divRef = useRef();

  // const scrollToElement = () => {
  //   const { current } = divRef;
  //   if (current !== null) {
  //     current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }

  // useEffect(() => {
  //   // Check if all necessary components are loaded
  //   const areComponentsLoaded = productInfo !== undefined;
  //   if (areComponentsLoaded) {
  //     scrollToElement();
  //   }
  // }, [productInfo]);

  return (
    <>
      <p style={{ color: "black", textAlign: "center" }}>
        <strong>Please select the required options</strong>
      </p>
      {/* <div className="content" ref={divRef}>  */}
      <div className="content" > 
        {productInfo.map((item, idx) => {
          return (
            <CleaningServices
              productInfo={item}
              packageInfo={packageInfo}
              setIsClicked={setIsClicked}
              key={idx}
              setParticularPackage={setParticularPackage}
            />
          );
        })}
      </div>
      {isClicked ? (
        <>
          <Plans particularPackage={particularPackage} 
              cart={cart} 
              totalPrice={totalPrice} 
              updateCartView={updateCartView} 
              calculateTotalPrice={calculateTotalPrice}
              setCart={setCart}
              setTotalPrice={setTotalPrice}
              addToCart={addToCart}
              />
          <Details particularPackage={particularPackage}/>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Options;