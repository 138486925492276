import React, { useEffect, useRef } from "react";

const Pillars = () => {
  const valueCardsRef = useRef([]);

  useEffect(() => {
    // Function to toggle description
    const toggleDescription = (event) => {
      event.preventDefault();
      const card = event.currentTarget;
      const description = card.querySelector('p');
      card.classList.toggle('active');
      description.style.maxHeight = card.classList.contains('active') ? `${description.scrollHeight}px` : '0';
    };

    // Attach event listener to each value card
    valueCardsRef.current.forEach(card => {
      card.addEventListener('click', toggleDescription);
    });

    // Cleanup function to remove event listeners
    return () => {
      valueCardsRef.current.forEach(card => {
        card.removeEventListener('click', toggleDescription);
      });
    };
  }, []);

  return (
    <div className="values-section">
      <div className="service-intro">
        <h2>The Pillars of Our Purpose</h2>
      </div>
      <div className="value-card" ref={(el) => valueCardsRef.current[0] = el}>
        <div className="icon-container mission"></div>
        <h3>OUR MISSION</h3>
        <p>
          Our mission at Easy Smart Cleaners is to provide unparalleled cleaning
          services that go beyond surface cleanliness. We are dedicated to
          creating hygienic and inviting spaces for our clients, ensuring their
          well-being and peace of mind. Through a combination of cutting-edge
          technology, eco-friendly practices, and a highly trained team, we aim
          to set new industry standards for reliability, professionalism, and
          customer satisfaction. Our mission is to be a trusted partner,
          delivering not just cleaning services, but a commitment to excellence
          and a healthier, more sustainable environment.
        </p>
      </div>
      <div className="value-card" ref={(el) => valueCardsRef.current[1] = el}>
        <div className="icon-container vision"></div>
        <h3>OUR VISION</h3>
        <p>
          Empowering Spaces, Enhancing Lives: Our vision is to be the leading
          provider of cleaning services, dedicated to transforming every space
          we touch into a pristine and healthy environment. We aspire to set the
          highest standards in cleanliness, innovation, and customer
          satisfaction, ensuring that our services contribute to the well-being
          and comfort of individuals, businesses, and communities alike. Through
          sustainable practices and a commitment to excellence, we envision a
          future where cleanliness becomes synonymous with a better quality of
          life.
        </p>
      </div>
      <div className="value-card" ref={(el) => valueCardsRef.current[2] = el}>
        <div className="icon-container values"></div>
        <h3>OUR VALUES</h3>
        <p>
          Our team are highly trained, full-time professional cleaners, totally
          reliable, providing quality cleaning services in Bangalore at
          affordable prices. We pride ourselves in listening to our customers,
          ensuring they receive a cleaning service they can trust and rely on
          whether it is Residential, commercial or corporate clean, we will be
          there when you need us. You need a professional service which provides
          consistent high quality cleaning to meet your own very high standards.
          We can customize our services based on your needs.
        </p>
      </div>
    </div>
  );
};

export default Pillars;
