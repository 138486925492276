import React, { useEffect, useState } from "react";


const NavBar = ({ active,  cart, totalPrice ,calculateTotalPrice, setCart, setTotalPrice  }) => {

  function clearCart() {
    setCart([]);
    setTotalPrice(0);
    localStorage.removeItem('cart');
  }
  
  function updateQuantity(index, quantity) {
    const updatedCart = [...cart];
    updatedCart[index].quantity = Number(quantity);
    setCart(updatedCart);
    updateLocalStorage(updatedCart);
    calculateTotalPrice(updatedCart);
  }

  function removeItem(index) {
    const updatedCart = cart.filter((_, i) => i !== index);
    setCart(updatedCart);
    updateLocalStorage(updatedCart);
    calculateTotalPrice(updatedCart);
  }

     
  function placeOrder() {
    alert('Place order functionality to be implemented.');
  }

  function updateLocalStorage() {
    localStorage.setItem('cart', JSON.stringify(cart));
    calculateTotalPrice();
  }


  let isCartDropdownOpen = false;
        
  function toggleCartDropdown() {
    const cartDropdown = document.getElementById('cartDropdown');
    
    // Toggle the display of the cart dropdown
    if (isCartDropdownOpen) {
      cartDropdown.style.display = 'none';
    } else {
      cartDropdown.style.display = 'block';
    }
  
    // Update the global state variable
    isCartDropdownOpen = !isCartDropdownOpen;
  }

  // Close cart dropdown when clicking outside of it
  document.addEventListener('click', function(event) {
    const cartDropdown = document.getElementById('cartDropdown');
    if (!event.target.closest('.cart-icon-container')) {
      cartDropdown.style.display = 'none';
    }
  });
  
    const handleChange = (event,index) => {
    // Ensure the value is within the range 1-99
    let value = Math.min(Math.max(Number(event.target.value), 0), 99);
    updateQuantity(index, value);
  };

  return (
    <div>
      <div className="header">
        <div className="nav">
          <a className={active === "home" ? "active-page" : ""} href="/">
            Home
          </a>
          <a
            className={active === "services" ? "active-page" : ""}
            href="/services"
          >
            Services
          </a>
          <a href="#">Pricing</a>
          <a href="#">Contact</a>
          <div className="cart-icon-container">
            <i
              className="fas fa-shopping-cart"
              onClick={() => { {
                calculateTotalPrice();  
                toggleCartDropdown();
              } }}
            ></i>

            <div className="cart-dropdown" id="cartDropdown" style={{width:"400px"}}>

              <div id="cartItemsContainer">
                  {cart.map( (item,idx) => {
                    if(item)
                    {
                      return(
                        <div style={{display:"flex", justifyContent:"center",alignItems:"center", padding:"5px 0px"}} key={idx}>
                          <img src={item.image} className="cart-item-img" alt={item.product} />
                          <span className="cart-item-name" style={{maxWidth:"150px"}}>{item.product}</span>
                          <span className="cart-item-name" style={{marginLeft:"20px"}}>₹{item.price}</span>
                          <input type="number" className="cart-item-quantity" value={item.quantity} min="1" max="99"  onChange={(event) => handleChange(event, idx)} />
                          <span className="cart-item-remove" id="cart-item-remove" onClick={ () => removeItem(idx)}>&times;</span>
                        </div> 
                      )
                    }
                  } )}
              </div>


              <div className="cart-total">
                Total: ₹<span id="cartTotal">{totalPrice}</span>
              </div>
              <button onClick={() => clearCart()}>Clear Cart</button>
              <button onClick={() => placeOrder()}>Place Order</button>
            </div>
          </div>
        </div>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com" target="_blank">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://instagram.com" target="_blank">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://wa.me/9663150947" target="_blank">
            <i className="fab fa-whatsapp"></i>
          </a>
          <a href="mailto:support@easysmartcleaners.com">
            <i className="fas fa-envelope"></i>
          </a>
          <a href="tel:+919663150947">
            <i className="fas fa-phone"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
