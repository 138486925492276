import React, { useEffect, useState } from "react";

import NavBar from "./NavBar";
import Footer from "./Footer";

// import Welcome from "./Home/Welcome";
import About from "./Home/About";
import Service from "./Home/Service";
import Pillars from "./Home/Pillars";
import Founder from "./Home/Founder";
import Map from "./Home/Map";
import Contact from "./Home/Contact";

import WelcomePage from "./Reusable Components/WelcomePage";
import about from "../ESC images/about_us.png";

export const welcomePageInfo = [
  {
    src: about,
    alt: "WHO WE ARE",
    title: "Welcome to Easy Smart Cleaners",
    desc: "At Easy Smart Cleaners, we are more than just a cleaning company; we are dedicated to creating cleaner, healthier, and happier spaces. With years of experience in the industry, our team is committed to delivering exceptional cleaning and maintenance services for both homes and offices. Our goal is to provide a hassle-free, reliable, and thorough cleaning experience to each of our clients",
  },
];



const Home = ({serviceData, cart, totalPrice,  updateCartView,calculateTotalPrice, setCart,setTotalPrice }) => {
  

  return (
    <>
      <NavBar   
        active="home"  
        cart={cart} 
        totalPrice={totalPrice} 
        updateCartView={updateCartView} 
        calculateTotalPrice={calculateTotalPrice}
        setCart={setCart}
        setTotalPrice={setTotalPrice}
      />      
      <WelcomePage welcomePageInfo={welcomePageInfo} />
      <About />
      <Service serviceData={serviceData} path={"services"}/>
      <Pillars />
      <Founder />
      <Map />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
