import React from "react";

import NavBar from "./NavBar";
import Footer from "./Footer";
import Service from "./Services/Service";

import ServiceCatalogue from "../ESC images/Service Catalogue.png";
import WelcomePage from "./Reusable Components/WelcomePage";

const welcomePageInfo = [
  {
    src: ServiceCatalogue,
    alt: "Service Catalogue",
    title: "Assistance Provided",
    desc: "Embrace the Excellence of Easy Smart Cleaners: Where every sweep, wipe, and polish is more than just cleaning—it's an art. Experience the true beauty of cleanliness as our expert team transforms your spaces. With our bespoke cleaning solutions, we don't just clean; we elevate environments to their most immaculate state. Feel the difference with our exceptional service, where every detail is meticulously attended to, ensuring a spotless, hygienic, and rejuvenating experience for your home or business. Choose the below services and feel the excellence in cleaning not just in promise, it's our standard. ",
  },
];

const Services = ({serviceData, cart, totalPrice,  updateCartView,calculateTotalPrice, setCart,setTotalPrice }) => {
  return (
    <>
      <NavBar active="services"  
       cart={cart} 
       totalPrice={totalPrice} 
       updateCartView={updateCartView} 
       calculateTotalPrice={calculateTotalPrice}
       setCart={setCart}
       setTotalPrice={setTotalPrice}
      />
      <WelcomePage welcomePageInfo={welcomePageInfo} />
      <Service serviceData={serviceData}/>
      <Footer />
    </>
  );
};

export default Services;
