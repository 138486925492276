import React from "react";

import founderImage from "../../ESC images/Archana.png";

const Founder = () => {
  return (
    <div className="founder-section">
      <div className="founder-image-container">
        <img
          src={founderImage}
          alt="Archana M., Founder and CEO"
          className="founder-image"
        />
      </div>
      <div className="founder-info">
        <h2>Founder and CEO</h2>
        <p className="founder-name">Archana M</p>
        <blockquote className="founder-saying">
          "Dedication, precision, and care are the hallmarks of our service.
          We're not just cleaning spaces; we're enhancing the lives within
          them."
        </blockquote>
      </div>
    </div>
  );
};

export default Founder;
