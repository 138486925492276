import React from "react";

const Map = () => {
  return (
    <div className="map-container">
      <div className="map-overlay">
        <h2>How to Find Us on the Map</h2>
      </div>
      <div className="gmap_canvas">
        <iframe
          width="100%"
          height="450"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=13.07228%2C77.51386&t=&z=19&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0" 
        />
      </div>
    </div>
  );
};

export default Map;
