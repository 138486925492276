import React  from "react";
import { useParams } from 'react-router-dom';

import NavBar from "../NavBar";
import Footer from "../Footer";
import About from "./About";
import Options from "./Options";
import WelcomePage from "../Reusable Components/WelcomePage";
import Service from "../Home/Service";

import ServiceCatalogue from "../../ESC images/Service Catalogue.png";

const MainService = ({ serviceData, cart, totalPrice, updateCartView, calculateTotalPrice, setCart, setTotalPrice, addToCart }) => {
  // Access the ID parameter from the URL using useParams
  let { id } = useParams();

  // Find the service data based on the ID
  const particularService = serviceData?.find(service => service.ServiceID.S === id);

  const welcomePageInfo = [
    {
      src: ServiceCatalogue,
      alt: "Service Catalogue",
      title: "Assistance Provided",
      desc: "Embrace the Excellence of Easy Smart Cleaners: Where every sweep, wipe, and polish is more than just cleaning—it's an art. Experience the true beauty of cleanliness as our expert team transforms your spaces. With our bespoke cleaning solutions, we don't just clean; we elevate environments to their most immaculate state. Feel the difference with our exceptional service, where every detail is meticulously attended to, ensuring a spotless, hygienic, and rejuvenating experience for your home or business. Choose the below services and feel the excellence in cleaning not just in promise, it's our standard. ",
    },
  ];

  

  return (
    <>
      <NavBar active="services"
        cart={cart}
        totalPrice={totalPrice}
        updateCartView={updateCartView}
        calculateTotalPrice={calculateTotalPrice}
        setCart={setCart}
        setTotalPrice={setTotalPrice}
      />
      <WelcomePage welcomePageInfo={welcomePageInfo} />
      <Service serviceData={serviceData} path={"."} />
      <About serviceData={particularService} />
      <Options serviceData={particularService}
        cart={cart}
        totalPrice={totalPrice}
        updateCartView={updateCartView}
        calculateTotalPrice={calculateTotalPrice}
        setCart={setCart}
        setTotalPrice={setTotalPrice}
        addToCart={addToCart} />
        <Footer />
    </>
  );
};

export default MainService;
