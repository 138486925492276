import React from "react";

const Service = ({serviceData}) => {


  return (
    <div className="content">

      { serviceData?.map( (item,idx) => {
        return(
          <div className="card-container" key={idx}>
           <a href={`/services/${item.ServiceID.S}`} style={{ textDecoration:"none" }}>
            <div className="card" style={{backgroundImage: `url(${item.ServiceImage.S})` }}></div>
            <p className="service-name">{item.ServiceName.S}</p>
          </a>
          </div>
        )
      })}
      
    </div>
      
  );
};

export default Service;
