import React from "react";

const CleaningServices = ({ productInfo, setIsClicked,packageInfo, setParticularPackage }) => {

  return (
    <>
      <div className="content-sub-service">
        <div className="card-container">
          <div className="card" style={{backgroundImage: `url("${productInfo[0].ProductImage.S}")`}}></div>
          <p
            className="service-name"
            style={{
              backgroundColor: " #fff",
              border: "1px solid #6A1B9A",
              color: "#6A1B9A",
              padding: "10px",
              textShadow: " 1px 1px 2px rgba(0,0,0,0.1)",
            }}
          >
            {productInfo[0].ProductName.S}
          </p>
        </div>
        <div className="card-container">
          {packageInfo.map((item,idx) => {
            if(item.ProductName.S === productInfo[0].ProductName.S)
              {
                return (
                  <a
                  key={idx}
                  className="service-name service-name-hover"
                  data-service="furnished-clasic"
                  onClick={() => {
                    setIsClicked(true); 
                    setParticularPackage(item);
                  }
                }
                href="#plans"
                style={{ textDecoration: "none", margin: "10px 0px" }}
                >
                  {item.PackageName.S}
                </a>
              )
            }
          })}
        </div>
      </div>
    </>
  );
};

export default CleaningServices;