import React, { useState } from "react";

import livingRoomBedRoom from "../../ESC images/services/Basic/Living room and bedroom/livingroom-bedroom.webp";
import Ceiling from "../../ESC images/services/Basic/Living room and bedroom/Dry Dusting/Ceiling.webp";
import walls from "../../ESC images/services/Basic/Living room and bedroom/Dry Dusting/walls.webp";
import furniture from "../../ESC images/services/Basic/Living room and bedroom/Dry Dusting/furniture.webp";
import cabinet from "../../ESC images/services/Basic/Living room and bedroom/Dry Dusting/cabinet.webp";
import corners from "../../ESC images/services/Basic/Living room and bedroom/Dry Dusting/corners.webp";
import fixtures from "../../ESC images/services/Basic/Living room and bedroom/Dry Dusting/fixtures.webp";
import windows from "../../ESC images/services/Basic/Living room and bedroom/Wet wiping/Window.webp";
import doors from "../../ESC images/services/Basic/Living room and bedroom/Wet wiping/doors.webp";
import electricalFitting from "../../ESC images/services/Basic/Living room and bedroom/Wet wiping/electrical-fittings.webp";

const Details = ({particularPackage}) => {
  const [isClicked, setIsClicked] = useState(false);
  const toggleModal = () => setIsClicked(!isClicked);



  return (
    <>
      <div>
        {/* <!-- Basic Cleaning Plan Overview --> */}
        <div className="cleaning-plan-overview">
          <div className="service-intro">
            <h2>Service Details</h2>
            <p>{particularPackage.ProductName.S} - {particularPackage.PackageName.S}</p>
          </div>


          <div className="cleaning-plan-container">
            {/* <!-- Living Room and Bedroom Card --> */}

            { particularPackage.ServiceDetails.L.map( (el,idx) =>{
                         
              return( 
                <div className="cleaning-plan-card" key={idx}>
                <h3>{el.M.Area.S}</h3>
                <ul>
                  {
                    el.M.Procedures.L.map((procedure,index) =>{
                      return(
                        <>
                        <li>
                          <strong>{procedure.M.Type.S}:</strong> {procedure.M.Description.S} 
                        </li>  
                        <br />
                        </>
                      )
                    } )   
                  }
                  {/* <!-- More details here --> */}
                </ul>
                {/* <!-- The "Know More" part --> */}
                <div className="modal-trigger" data-modal="basicCleaningModal">
                  <strong onClick={() => toggleModal()}>Know More</strong>
                </div>
              </div>
              )
            })
            }
 
          </div>

          {/* <!-- More sections like Bathroom, Balcony, etc. --> */}
          <div className="Exclusions">
            <p>
              <strong>Exclusions</strong>
            </p>
            <hr />
            <ul>
              {particularPackage.ExclusionsDetails?.L.map( (exclusion,idx) => {
                return (
                  <li key={idx}>  {exclusion.S} </li>  
                )
              } )}
            </ul>
          </div>
        </div>
      </div>

      {isClicked && (
        <div
          id="basicCleaningModal"
          className={`modal ${isClicked ? "modal-active" : ""} `}
        >
          <div className="modal-overlay" onClick={() => toggleModal()}>
            shddfsjkfhdskjhfkjdshfjdskhfjksdhfkdshk
          </div>
          <div className="modal-content">
            <span className="close" onClick={() => toggleModal()}>
              &times;
            </span>
            <div className="service-details">
              <div className="service-icon">
                <img
                  src={livingRoomBedRoom}
                  alt="Icon representing dry dusting"
                />
              </div>
              <div className="service-text">
                <div className="section-title header1">
                  Living room and bedroom
                </div>
                <div className="section-subtitle sub-header">Dry Dusting</div>
              </div>
            </div>
            <div className="service-details">
              <div className="service-text">
                <div className="sub-sub-header">
                  <h3>Ceiling</h3>
                </div>
                <hr />
                <div className="service-image service-image-mobile">
                  <img src={Ceiling} alt="Ceiling Cleaning" />
                </div>
                <div className="detail-info">
                  <p>
                    <strong>Supplies Needed:</strong>
                  </p>
                  <ul className="supplies">
                    <li>Extendable microfiber duster or telescopic duster.</li>
                    <li>Step ladder (for high ceilings).</li>
                    <li>Protective eyewear and dust mask.</li>
                    <li>Furniture covers or old sheets.</li>
                  </ul>
                  <p>
                    <strong>Steps:</strong>
                  </p>
                  <ol className="steps">
                    <li>
                      <strong>Prepare the Room:</strong> Move fragile items,
                      cover furniture with sheets to catch dust.
                    </li>
                    <li>
                      <strong>Safety Gear:</strong> Wear protective eyewear and
                      a dust mask.
                    </li>
                    <li>
                      <strong>Dust the Ceiling:</strong> Start from one corner,
                      using the duster on an extendable handle to reach across
                      the ceiling. Use gentle motions to avoid scattering dust.
                      Focus on corners and areas around light fixtures or
                      ceiling fans.
                    </li>
                    <li>
                      <strong>Clean Fixtures:</strong> Carefully dust any light
                      fixtures or ceiling fans.
                    </li>
                    <li>
                      <strong>Wrap Up:</strong> Remove and clean furniture
                      covers outside. Wash or shake out the duster.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
              </div>
              <div className="service-image service-image-desktop">
                <img src={Ceiling} alt="Ceiling Cleaning" />
              </div>
            </div>
            <div className="service-details">
              <div className="service-image service-image-desktop">
                <img src={walls} alt="Ceiling Cleaning" />
              </div>
              <div className="service-text">
                <div className="sub-sub-header">
                  <h3>Walls</h3>
                </div>
                <hr />
                <div className="service-image service-image-mobile">
                  <img src={walls} alt="Ceiling Cleaning" />
                </div>
                <div className="detail-info">
                  <p>
                    <strong>Supplies Needed:</strong>
                  </p>
                  <ul className="supplies">
                    <li>
                      Microfiber cloth or duster with an extendable handle.
                    </li>
                    <li>Step ladder for higher areas.</li>
                    <li>Protective eyewear (optional).</li>
                  </ul>
                  <p>
                    <strong>Steps:</strong>
                  </p>
                  <ol className="steps">
                    <li>
                      <strong>Prepare the Area:</strong> Move furniture slightly
                      away from walls and cover it to protect from dust.
                    </li>
                    <li>
                      <strong>Start at the Top:</strong> Begin dusting at the
                      top of the wall and move downwards to ensure falling dust
                      is caught on the way down.
                    </li>
                    <li>
                      <strong>Use Gentle Motions:</strong> Gently wipe the wall
                      in a downward motion to collect dust without damaging the
                      paint or wallpaper.
                    </li>
                    <li>
                      <strong>Reach High Areas:</strong> Use the step ladder
                      safely to reach higher areas, ensuring it's stable before
                      climbing.
                    </li>
                    <li>
                      <strong>Focus on Corners:</strong> Pay extra attention to
                      corners and edges where dust tends to accumulate.
                    </li>
                    <li>
                      <strong>Clean Light Switches and Wall Fixtures:</strong>{" "}
                      Lightly dust around switches, outlets, and any
                      wall-mounted fixtures.
                    </li>
                    <li>
                      <strong>Final Touches:</strong> After dusting, return the
                      room to its original state, moving furniture back into
                      place.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="service-details">
              <div className="service-text">
                <div className="sub-sub-header">
                  <h3>Furniture</h3>
                </div>
                <hr />
                <div className="service-image service-image-mobile">
                  <img src={furniture} alt="Ceiling Cleaning" />
                </div>
                <div className="detail-info">
                  <p>
                    <strong>Supplies Needed:</strong>
                  </p>
                  <ul className="supplies">
                    <li>
                      <strong>Microfiber cloths:</strong> Ideal for trapping
                      dust without scratching surfaces.
                    </li>
                    <li>
                      <strong>Soft-bristled brush:</strong> Useful for detailed
                      or intricate areas.
                    </li>
                    <li>
                      <strong>Extendable duster:</strong> For hard-to-reach
                      spots.
                    </li>
                  </ul>
                  <p>
                    <strong>Steps:</strong>
                  </p>
                  <ol className="steps">
                    <li>
                      <strong>Prep the Space:</strong> Clear any clutter from
                      furniture surfaces to ensure thorough access.
                    </li>
                    <li>
                      <strong>Dust Top to Bottom:</strong> Begin dusting from
                      the highest point on each piece of furniture, moving
                      downwards. This method ensures dust falls onto areas yet
                      to be cleaned.
                    </li>
                    <li>
                      <strong>Use Gentle Motions:</strong> Wipe surfaces with a
                      microfiber cloth using gentle, circular motions to capture
                      dust effectively without spreading it around.
                    </li>
                    <li>
                      <strong>Tackle Details and Hard-to-Reach Areas:</strong>{" "}
                      Use a soft-bristled brush or an extendable duster for
                      intricate details, crevices, and areas behind or beneath
                      furniture
                    </li>
                    <li>
                      <strong>Proceed with Order:</strong> Dust furniture
                      systematically, moving from one piece to the next to
                      ensure no area is overlooked. Start with larger items like
                      wardrobes and dressers, then proceed to smaller pieces.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
              </div>
              <div className="service-image service-image-desktop">
                <img src={furniture} alt="Ceiling Cleaning" />
              </div>
            </div>
            <div className="service-details">
              <div className="service-image service-image-desktop">
                <img src={cabinet} alt="Ceiling Cleaning" />
              </div>
              <div className="service-text">
                <div className="sub-sub-header">
                  <h3>Cabinets/Shelves</h3>
                </div>
                <hr />
                <div className="service-image service-image-mobile">
                  <img src={cabinet} alt="Ceiling Cleaning" />
                </div>
                <div className="detail-info">
                  <p>
                    <strong>Supplies Needed:</strong>
                  </p>
                  <ul className="supplies">
                    <li>
                      <strong>Microfiber cloths:</strong> They're gentle on
                      surfaces and trap dust effectively.
                    </li>
                    <li>
                      <strong>Soft-bristled brush or dusting brush:</strong> For
                      getting into nooks and crannies.
                    </li>
                    <li>
                      <strong>Extendable duster:</strong> For reaching top
                      shelves without stretching.
                    </li>
                  </ul>
                  <p>
                    <strong>Steps:</strong>
                  </p>
                  <ol className="steps">
                    <li>
                      <strong>Clear the Shelves:</strong> Temporarily remove
                      items from shelves and cabinets to ensure a thorough
                      clean. This step also prevents items from being knocked
                      over.
                    </li>
                    <li>
                      <strong>Start at the Top:</strong> Begin dusting at the
                      highest point, moving downwards. This method ensures any
                      dislodged dust falls on areas you haven't cleaned yet.
                    </li>
                    <li>
                      <strong>Use the Right Tools:</strong> Wipe surfaces with a
                      microfiber cloth using light, sweeping motions. Use a
                      soft-bristled brush for detailed areas, edges, and
                      corners.
                    </li>
                    <li>
                      <strong>Handle Items with Care:</strong> Dust each removed
                      item with your microfiber cloth before placing it back.
                      This prevents reintroducing dust.
                    </li>
                    <li>
                      <strong>Don’t Forget the Exterior:</strong> Dust the
                      cabinet or shelf exterior, including the top surface,
                      sides, and any decorative details.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="service-details">
              <div className="service-text">
                <div className="sub-sub-header">
                  <h3>Corners</h3>
                </div>
                <hr />
                <div className="service-image service-image-mobile">
                  <img src={corners} alt="Ceiling Cleaning" />
                </div>
                <div className="detail-info">
                  <p>
                    <strong>Supplies Needed:</strong>
                  </p>
                  <ul className="supplies">
                    <li>
                      <strong>Extendable duster with a long handle</strong>{" "}
                      Ideal for reaching high corners without the need for a
                      ladder.
                    </li>
                    <li>
                      <strong>Microfiber cloth:</strong> For lower corners and
                      more accessible areas.
                    </li>
                    <li>
                      <strong>
                        Soft-bristled brush attachment (optional):{" "}
                      </strong>{" "}
                      Useful for dislodging stubborn dust in tight spots.
                    </li>
                  </ul>
                  <p>
                    <strong>Steps:</strong>
                  </p>
                  <ol className="steps">
                    <li>
                      <strong>Prep the Space:</strong> Briefly inspect the
                      corners for any cobwebs or significant dust accumulation.
                    </li>
                    <li>
                      <strong>Top-Down Approach:</strong> Start with the upper
                      corners of the room. Extend your duster to reach these
                      areas without straining. Move the duster in gentle
                      sweeping motions to collect dust.
                    </li>
                    <li>
                      <strong>Lower Corners:</strong> Use a microfiber cloth to
                      dust lower corners and the meeting points of walls and
                      flooring. Fold the cloth over your finger for better reach
                      into tight spots.
                    </li>
                    <li>
                      <strong>Detailing:</strong> For corners with noticeable
                      dust build-up or cobwebs, lightly use a soft-bristled
                      brush to loosen the debris before wiping it away with your
                      microfiber cloth.
                    </li>
                    <li>
                      <strong>Systematic Cleaning:</strong> Move methodically
                      around the room, ensuring you address all corners for a
                      uniform clean.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
              </div>
              <div className="service-image service-image-desktop">
                <img src={corners} alt="Ceiling Cleaning" />
              </div>
            </div>
            <div className="service-details">
              <div className="service-image service-image-desktop">
                <img src={fixtures} alt="Ceiling Cleaning" />
              </div>
              <div className="service-text">
                <div className="sub-sub-header">
                  <h3>Fixtures</h3>
                </div>
                <hr />
                <div className="service-image service-image-mobile">
                  <img src={fixtures} alt="Ceiling Cleaning" />
                </div>
                <div className="detail-info">
                  <p>
                    <strong>Supplies Needed:</strong>
                  </p>
                  <ul className="supplies">
                    <li>
                      <strong>Microfiber cloth:</strong> Effective for trapping
                      dust without scratching surfaces.
                    </li>
                    <li>
                      <strong>Soft-bristled brush:</strong> Ideal for detailed
                      and delicate fixtures.
                    </li>
                    <li>
                      <strong>Extendable duster:</strong> For high or
                      hard-to-reach fixtures.
                    </li>
                  </ul>
                  <p>
                    <strong>Steps:</strong>
                  </p>
                  <ol className="steps">
                    <li>
                      <strong>Safety First:</strong> Ensure that lights are
                      turned off and cool to the touch before cleaning.
                    </li>
                    <li>
                      <strong>High Fixtures:</strong> Use an extendable duster
                      for ceiling fans, chandeliers, or high-mounted lights.
                      Gently sweep to remove dust, taking care not to apply too
                      much pressure.
                    </li>
                    <li>
                      <strong>Detail Dusting:</strong> For detailed or intricate
                      fixtures, lightly use a soft-bristled brush to loosen dust
                      without damaging the fixture.
                    </li>
                    <li>
                      <strong>Accessible Fixtures:</strong> Wipe down lamps,
                      sconces, and other easily reachable fixtures with a
                      microfiber cloth, using gentle, circular motions.
                    </li>
                    <li>
                      <strong>Careful Handling:</strong> Be delicate with shades
                      and bulbs. Dust lamp shades with a clean, dry microfiber
                      cloth, and for bulbs, ensure they're cool before dusting.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="service-details">
              <div className="service-icon">
                <img
                  src={livingRoomBedRoom}
                  alt="Icon representing dry dusting"
                />
              </div>
              <div className="service-text">
                <div className="section-title header1">
                  Living room and bedroom
                </div>
                <div className="section-subtitle sub-header">Wet Wiping</div>
              </div>
            </div>
            <div className="service-details">
              <div className="service-text">
                <div className="sub-sub-header">
                  <h3>windows</h3>
                </div>
                <hr />
                <div className="service-image service-image-mobile">
                  <img src={windows} alt="Ceiling Cleaning" />
                </div>
                <div className="detail-info">
                  <p>
                    <strong>Supplies Needed:</strong>
                  </p>
                  <ul className="supplies">
                    <li>
                      <strong>Squeegee:</strong> Effective for streak-free
                      drying of window glass.
                    </li>
                    <li>
                      <strong>Microfiber cloth:</strong> Use one for cleaning
                      and another for drying.
                    </li>
                    <li>
                      <strong>Warm, soapy water:</strong> A mild detergent or
                      dish soap mixed with warm water.
                    </li>
                    <li>
                      <strong>Spray bottle (optional):</strong> For evenly
                      distributing the cleaning solution.
                    </li>
                    <li>
                      <strong>Dry towel or cloth</strong> For wiping sills and
                      frames after cleaning.
                    </li>
                    <p>
                      <strong>Steps:</strong>
                    </p>
                  </ul>
                  <ol className="steps">
                    <li>
                      <strong>Prepare the Solution: </strong> Mix warm water
                      with a mild detergent in a bucket. Avoid using too much
                      soap to prevent streaks.
                    </li>
                    <li>
                      <strong>Dust Off:</strong> Before wet wiping, use a dry
                      microfiber cloth to dust off the window frames and sills
                      to remove any loose dirt.
                    </li>
                    <li>
                      <strong>Wet Wiping:</strong> Dip a clean microfiber cloth
                      into the soapy solution, wring out excess water, and
                      gently wipe the window glass. Start from the top corner
                      and work your way down in a zigzag or S pattern for
                      efficiency.
                    </li>
                    <li>
                      <strong>Detail Corners:</strong> For corners and edges,
                      you might use a smaller cloth or a soft-bristled brush to
                      remove dirt without damaging the sealant or paint.
                    </li>
                    <li>
                      <strong>Rinse (if needed):</strong> If your windows are
                      particularly dirty, a second pass with just water might be
                      needed to remove soap residue.
                    </li>
                    <li>
                      <strong>Dry the Windows:</strong> Use a dry cloth, towel,
                      or squeegee to remove any remaining moisture from the
                      glass. Ensure no streaks are left behind by drying in a
                      uniform direction.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
              </div>
              <div className="service-image service-image-desktop">
                <img src={windows} alt="Ceiling Cleaning" />
              </div>
            </div>
            <div className="service-details">
              <div className="service-image service-image-desktop">
                <img src={doors} alt="Ceiling Cleaning" />
              </div>
              <div className="service-text">
                <div className="sub-sub-header">
                  <h3>Doors</h3>
                </div>
                <hr />
                <div className="service-image service-image-mobile">
                  <img src={doors} alt="Ceiling Cleaning" />
                </div>
                <div className="detail-info">
                  <p>
                    <strong>Supplies Needed:</strong>
                  </p>
                  <ul className="supplies">
                    <li>
                      <strong>Microfiber cloths:</strong> One for cleaning and
                      another for drying.
                    </li>
                    <li>
                      <strong>Warm, soapy water:</strong> A gentle detergent
                      mixed with warm water in a bucket.
                    </li>
                    <li>
                      <strong>Sponge or soft cloth:</strong> For applying the
                      cleaning solution.
                    </li>
                    <li>
                      <strong>Dry towel:</strong> For drying the door surfaces
                      after cleaning.
                    </li>
                  </ul>
                  <p>
                    <strong>Steps:</strong>
                  </p>
                  <ol className="steps">
                    <li>
                      <strong>Prepare the Solution:</strong> Mix a mild
                      detergent with warm water in a bucket.
                    </li>
                    <li>
                      <strong>Dust First:</strong> Quickly dust the door,
                      especially the top and sides, with a dry microfiber cloth
                      to remove loose dirt and debris.
                    </li>
                    <li>
                      <strong>Clean Top to Bottom:</strong> Dip your sponge or
                      soft cloth into the soapy solution, wring out excess
                      liquid, and gently wipe the door from top to bottom. This
                      method ensures that any drips will be cleaned as you move
                      downwards.
                    </li>
                    <li>
                      <strong>Focus on Details:</strong> Pay special attention
                      to door handles, edges, and any decorative details. Use a
                      smaller cloth or sponge to reach into crevices and around
                      hardware.
                    </li>
                    <li>
                      <strong>Rinse if Necessary:</strong> If the door is
                      particularly dirty, rinse the cloth in clean water, wring
                      it out, and wipe the door again to remove any soap
                      residue.
                    </li>
                    <li>
                      <strong>Dry Thoroughly:</strong> Use a dry towel to wipe
                      down the door, absorbing any remaining moisture to prevent
                      streaks and water marks.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div className="service-details">
              <div className="service-text">
                <div className="sub-sub-header">
                  <h3>Electrical Fittings</h3>
                </div>
                <hr />
                <div className="service-image service-image-mobile">
                  <img src={electricalFitting} alt="Ceiling Cleaning" />
                </div>
                <div className="detail-info">
                  <p>
                    <strong>Supplies Needed:</strong>
                  </p>
                  <ul className="supplies">
                    <li>
                      <strong>Microfiber cloth:</strong> Preferably one that's
                      slightly damp and another dry one for wiping off.
                    </li>
                    <li>
                      <strong>Mild cleaning solution:</strong> A very diluted
                      mix of water and mild detergent in a spray bottle.
                    </li>
                    <li>
                      <strong>Toothbrush (optional):</strong> For detailed
                      cleaning around switches and hard-to-reach areas.
                    </li>
                    <p>
                      <strong>Steps:</strong>
                    </p>
                  </ul>
                  <ol className="steps">
                    <li>
                      <strong>Safety First:</strong> Ensure the electrical
                      fittings are turned off or, if possible, that the power
                      supply to these fittings is disconnected.
                    </li>
                    <li>
                      <strong>Prepare the Cleaning Solution:</strong> Mix a few
                      drops of mild detergent in water and lightly spray it onto
                      the microfiber cloth. The cloth should be damp, not wet,
                      to avoid any risk of water entering the electrical
                      components.
                    </li>
                    <li>
                      <strong>Gentle Cleaning:</strong> Gently wipe the surface
                      of switches, outlets, and other electrical fittings with
                      the damp cloth. Avoid applying too much pressure or
                      allowing moisture to seep into the fittings.
                    </li>
                    <li>
                      <strong>Detailing:</strong> Use a dry, soft-bristled
                      toothbrush for any detailed areas that are difficult to
                      clean with the cloth alone.
                    </li>
                    <li>
                      <strong>Wipe Dry:</strong> Immediately after wiping with
                      the damp cloth, use a dry microfiber cloth to remove any
                      moisture. This step is crucial to prevent any damage or
                      safety hazards.
                    </li>
                    <li>
                      <strong>Regular Maintenance:</strong> Regularly dusting
                      before wet wiping can reduce the need for frequent wet
                      cleaning and minimize the accumulation of dirt and grime.
                    </li>
                  </ol>
                  <br />
                  <br />
                </div>
              </div>
              <div className="service-image service-image-desktop">
                <img src={electricalFitting} alt="Ceiling Cleaning" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Details;
