import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Plans = ({particularPackage , updateLocalStorage,cart,addToCart}) => {
        
    function updateLocalStorage() {
      localStorage.setItem('cart', JSON.stringify(cart));
    }

    const [sizeData, setSizeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        try {
          // Fetch data from a URL
          const response = await fetch(`https://mozrgptz3a.execute-api.ap-south-1.amazonaws.com/ESC-Production/esc-size?timestamp=${Date.now()}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const fetchedData = await response.json();
          
          setSizeData(fetchedData); // Set the fetched data
          setLoading(false); // Set loading to false after data is fetched
          updateLocalStorage();
        } catch (error) {
          console.error('Error fetching data:', error);
        } 
      };
      fetchData();
    }, [cart]);

    const particularSize = [];

    sizeData.map(  (size,idx) => {
      if(particularPackage.PackageName.S === size.PackageName.S)
        particularSize.push( size );
    })

  return (
    <div
      className="furnished-clasic service-specific"
      id="plans"
      style={{ display: "" }}
    >
      <div className="service-intro">
        <h2>{particularPackage.ProductName.S} - {particularPackage.PackageName.S}</h2>
        <p>{particularPackage.PackageDescription?.S}</p>
      </div>
      <div className="pricing-cards-container">

      {particularSize.map( (size,idx) => {
        return(
          <div className="pricing-card" key={idx}>
            <img
              src={size.SizeImage.S}
              alt={size.SizeName.S}
              className="pricing-card-image"
            />
            <p className="price">₹ {size.SizePrice.S}</p>
            <button
              className="add-to-cart-btn"
              onClick={()=> {
                addToCart(`${size.PackageName?.S}-${size.SizeName.S}`, `${size.SizePrice.S}`, `${size.SizeImage.S}`);
                setClicked(!clicked);
              }}
              style={{
                border: "none",
                cursor: "pointer",
                animation: clicked ? "clickedAnimation 1s ease-in-out" : "initialAnimation 1s ease-in-out",
              }}
            >
              Add to Cart
            </button>
          </div>
        )
      } )}

      </div>
    </div>
  );
};

export default Plans;
<ToastContainer />;
