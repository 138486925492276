import React from "react";

const Service = ({serviceData,path}) => {
  return (
    <>
      <div className="service-intro">
        {path!="." ? 
        <>
            <h2>Service Offered</h2>
            <p>Ready for a Spotless Space? Get Started</p>       
        </>
        : ""}
      </div>
      <div className="content">
        {serviceData?.map((item, idx) => {
          return (
            <div className="card-container" key={idx}>
              <a href={`${path}/${item.ServiceID.S}`} style={{textDecoration:"none"}}>
                <div className="card" style={{backgroundImage: `url(${item.ServiceImage.S})`}}></div>
                <p className="service-name">{item.ServiceName.S}</p>
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Service;
