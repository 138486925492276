import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const Footer = () => {
  const [cookiePreference, setCookiePreference] = useState("none");
  const [cookieSettings, setCookieSettings] = useState("none");
  const [learnMore, setLearnMore] = useState("none");
  const [email, setEmail] = useState('');

  useEffect(() => {
    const cookieConsent = Cookies.get('cookieConsent');
    const uniqueId = getOrCreateUniqueId();
    if (!cookieConsent && !uniqueId) {
      checkUserIdInS3().then(isInS3 => {
        if (!isInS3) {
          showCookiePreference();
        }
      });
    }
  }, []);

  const setCookie = (name, value, days) => {
    Cookies.set(name, value, { expires: days });
  };

  const getCookie = (name) => {
    return Cookies.get(name);
  };

  const getOrCreateUniqueId = () => {
    let uniqueId = getCookie('uniqueUserId');
    if (!uniqueId) {
      uniqueId = generateUniqueId();
      setCookie('uniqueUserId', uniqueId, 365);
    }
    return uniqueId;
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };

  const checkUserIdInS3 = () => {
    const uniqueUserId = getOrCreateUniqueId();
    if (!uniqueUserId) return Promise.resolve(false);

    const url = 'https://3ks8be550h.execute-api.ap-south-1.amazonaws.com/Dev/cookie-preferences';

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userId: uniqueUserId, checkUserId: true })
    })
    .then(response => response.json())
    .then(data => data.existsInS3)
    .catch(error => {
      console.error('Error:', error);
      return false;
    });
  };

  const showCookiePreference = () => {
    setCookiePreference("block");
  };

  const hideCookiePreference = () => {
    setCookiePreference("none");
  };

  const handleCookieSettings = () => {
    setCookieSettings(cookieSettings === "none" ? "block" : "none");
  };

  const handleLearnMore = () => {
    setLearnMore(learnMore === "none" ? "block" : "none");
  };

  const handleEmailInputChange = (event) => {
    setEmail(event.target.value);
  };

  const saveEmail = () => {
    if (email) {
      setCookie("email", email, 30);
      setEmail('');
      hideCookiePreference();
    } 
  };

  return (
    <div className="footer">
      <footer>
        <p>
          Privacy | Site Terms |
          <span id="cookie-preferences-link" style={{ cursor: "pointer" }} onClick={showCookiePreference}> &nbsp; Cookie Preferences </span>
          &nbsp; | &copy; 2023, Eastghats LLC. All rights reserved.
        </p>
      </footer>

      <div id="cookie-consent-banner" className="cookie-consent-banner" style={{ display: cookiePreference, color: "black" }}>
        <p>We use cookies to personalize content and ads, provide social media features, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies. <a style={{ color: "blue" }} onClick={handleLearnMore}>Learn more</a>.</p>
        <button id="accept-all-cookies" onClick={hideCookiePreference}>Accept All</button>
        <button id="cookie-settings" onClick={handleCookieSettings}>Cookie Settings</button>
        <button onClick={hideCookiePreference}>Close</button>
      </div>

      <div id="cookie-settings-modal" className="cookie-modal" style={{ display: cookieSettings, color: "black" }}  >
        <div className="cookie-modal-content">
          <span className="close-modal" onClick={handleCookieSettings}>&times;</span>
          <h2>Cookie Preferences</h2>
          <p>Select which cookies you want to accept:</p>

          <div className="cookie-option">
            <input type="checkbox" id="essential-cookies" name="cookie-type" value="essential" checked disabled />
            <label htmlFor="essential-cookies">Essential Cookies</label>
          </div>

          <div className="cookie-option">
            <input type="checkbox" id="performance-cookies" name="cookie-type" value="performance" />
            <label htmlFor="performance-cookies">Performance Cookies</label>
          </div>

          <div className="cookie-option">
            <input type="checkbox" id="functionality-cookies" name="cookie-type" value="functionality" />
            <label htmlFor="functionality-cookies">Functionality Cookies</label>
          </div>

          <div className="cookie-option">
            <input type="checkbox" id="advertising-cookies" name="cookie-type" value="advertising" />
            <label htmlFor="advertising-cookies">Advertising Cookies</label>
          </div>

          <button id="save-cookie-settings" onClick={hideCookiePreference}>Save Preferences</button>
        </div>
      </div>

      <div id="learn-more-modal" className="cookie-modal" style={{ display: learnMore, color: "black" }}>
        <div className="cookie-modal-content">
          <span className="close-learn-more" onClick={handleLearnMore}>&times;</span>
          <h2>Cookie Usage at Easy Smart Cleaners</h2>
          <p>At Easy Smart Cleaners, we prioritize your privacy and digital experience. Here's how we use cookies:</p>

          <ul>
            <li><strong>Necessary Cookies:</strong> These are essential for the website to function properly. They include, for example, cookies that enable you to log into secure areas of our website.</li>
            <li><strong>Performance Cookies:</strong> These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. This helps us improve the way our website works.</li>
            <li><strong>Functionality Cookies:</strong> These are used to recognize you when you return to our website. This enables us to personalize our content for you and remember your preferences.</li>
            <li><strong>Advertising Cookies:</strong> These cookies record your visit to our website, the pages you have visited, and the links you have followed. We use this information to make our website and the advertising displayed on it more relevant to your interests.</li>
          </ul>

          <p>Your consent to our use of cookies will enable us to provide the best possible experience and service to you. You can change your cookie preferences at any time.</p>

          <button onClick={handleLearnMore} className="modal-close-btn ">Close</button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
