import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./Components/Home";
import Services from "./Components/Services";
import MainService from "./Components/ServiceComponents/MainService";

const App = () => {


  const [serviceData, setServiceData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(`https://mozrgptz3a.execute-api.ap-south-1.amazonaws.com/ESC-Production/esc-service?timestamp=${Date.now()}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const fetchedData = await response.json();
        
         // Sort the fetched data based on numeric value of ServicePosition
        const sortedData = fetchedData.sort((a, b) => {
          // Convert ServicePosition values from strings to numbers
          const servicePositionA = parseInt(a.ServicePosition.N, 10);
          const servicePositionB = parseInt(b.ServicePosition.N, 10);

        // Compare the numeric values
        return servicePositionA - servicePositionB;
      });
  
        // Set the sorted data to state
        setServiceData(sortedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  
      // To pass into NavBar
      const [cart, setCart] = useState([]);
      const [totalPrice, setTotalPrice] = useState(0);

      useEffect(() => {
        updateCartView();
      }, []);

      function updateCartView() {
        const savedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCart(savedCart);
        calculateTotalPrice(savedCart);
      }

      function addToCart(product, price, image) {
        const itemIndex = cart.findIndex(item => item.product === product);
        if (itemIndex > -1) {
          const updatedCart = [...cart];
          updatedCart[itemIndex].quantity++;
          setCart(updatedCart);
        } else {
          setCart(prevCart => [...prevCart, { product, price, image, quantity: 1 }]);
        }
      }

      function calculateTotalPrice() {
        const totalPrice = cart.reduce((total, cartItem) => {
          return total + cartItem.price * cartItem.quantity;
        }, 0);
        setTotalPrice(totalPrice);
      }


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home serviceData={serviceData}              
              cart={cart} 
              totalPrice={totalPrice} 
              updateCartView={updateCartView} 
              calculateTotalPrice={calculateTotalPrice}
              setCart={setCart}
              setTotalPrice={setTotalPrice}
              />} 
          />
          <Route path="/services" element={<Services serviceData={serviceData}               
              cart={cart} 
              totalPrice={totalPrice}               
              updateCartView={updateCartView} 
              calculateTotalPrice={calculateTotalPrice} 
              setCart={setCart}
              setTotalPrice={setTotalPrice}
              />} 
          />
          <Route path="/services/:id" element={
            <MainService 
              serviceData={serviceData}       
              cart={cart} 
              totalPrice={totalPrice}                
              updateCartView={updateCartView} 
              calculateTotalPrice={calculateTotalPrice}
              addToCart={addToCart}
              setCart={setCart}
              setTotalPrice={setTotalPrice}
            />} 
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
